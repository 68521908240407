import Service from "@/services/Service"
import Session from "@/model/Session"
import { log } from "@/utilities/Logging"
import type License from "@/model/License"
import ObjectUtils from "@/utilities/ObjectUtils"
import User from "@/model/User"
import { Transaction, withStore, withStoreAsync } from "@/utilities/WithStore"
import StorageService, { reconstructSession } from "./storage/StorageService"
import eventBus from "./EventBus"
import { setUserId } from "./OpenReplay"


export default class AutorizationService extends Service {

  constructor(storageService: StorageService) {
    super(storageService)
  }

  public apiKey(): string | undefined {
    return this.storageService.data().session?.apiKey
  }

  updateDevicesForSession(deviceNames: string[]) {
    return withStore(this, () => {
      this.storeData().session!!.deviceNames = deviceNames;
    }, Transaction.READ_WRITE)();
  }


  async newClientSessionForDevice(deviceIdentifier: string): Promise<Session | undefined> {
    return withStoreAsync(this, async () => {
      try {
        let session = (await this._fetch(
          this.data_url + "/sessions/deviceIdentifier/" + deviceIdentifier + "?clientDevice=Y",
          {
            method: "POST"
          })) as Session
        setUserId(session.deviceConfiguration?.deviceIdentifier ?? 'NO DEVICE')
        reconstructSession(session)
        eventBus.value.emit("event:sessionUpdated")
        return session
      } catch (error) {
        log("error", "Error fetching session for device: " + error)
      }
      return undefined
    }, Transaction.READ_WRITE)()
  }

  async moveUserToLicense(user: User, targetLicense: string) {
    return await this._fetch(this.data_url + "/users/migrateFromToLicense", {
      method: "POST",
      body: {
        userName: user.username,
        fromLicenseCode: user.licenseCode,
        toLicenseCode: targetLicense
      }
    });
  }

  async assignUserToLocation(licenseCode: string, userName: string, locationName: string, locationUserNumber: string) {
    return await this._fetch(this.data_url + "/users/assignToLocation", {
      method: "POST",
      body: {
        licenseCode,
        userName,
        locationName,
        locationUserNumber
      }
    });
  }

  async fetchUsersFor(licenseCode: string | undefined): Promise<User[]> {
    if (licenseCode) {
      return this._fetch(this.data_url + "/users?licenseCode=" + licenseCode) as Promise<User[]>
    } else {
      return this._fetch(this.data_url + "/users") as Promise<User[]>
    }
  }

  async deleteLicense(licenseCode: string) {
    try {
      await this._fetch(`${this.data_url}/licenses/${licenseCode}`, {
        method: "DELETE"
      })
      return true;
    } catch (error) {
      log("error", "Error deleting license: " + error)
      return false;
    }
  }

  async retrieveSession(sessionId: string): Promise<Session | undefined> {
    if (!sessionId?.trim()) {
      throw new Error('Invalid session ID');
    }
    return await withStoreAsync(this, async () => {
      try {
        let session = await this._fetch(
          this.data_url + "/sessions/" + sessionId) as Session;

        setUserId(session.user?.username ?? 'No user found in session.')

        if (!session) {
          throw new Error('Session not found');
        }

        // Clear any existing session data
        this.storeData().session = undefined;

        if (session.orders) {
          session.orders = session.orders.map((order, index) => ({
            ...order,
            viewOrderNumber: index + 1,
            getDeviceNames: order.getDeviceNames
          }));
        }


        session = reconstructSession(session)
        this.storeData().session = session;

        eventBus.value.emit("event:sessionUpdated");
        return session;
      } catch (error) {
        log("error", "Error fetching session: " + error);
        throw error; // Propagate error for proper handling
      }
    }, Transaction.READ_WRITE)();
  }

  isSessionActive() {
    withStoreAsync(this, async () => {
      return this.storeData().session?.apiKey !== undefined
    })()
  }

  deleteUser(userId: string, licenseCode: string) {
    return this._fetch(`${this.data_url}/users/${userId}?licenseCode=${licenseCode}`, {
      method: "DELETE"
    })
  }

  async checkSessionValid(sessionId: string): Promise<SessionState> {
    return withStoreAsync(this, async () => {
      if (this.storeData().session?.apiKey !== undefined) {
        try {
          let response = await this._fetch(`${this.data_url}/sessions/${sessionId}`) as Session;
          if (response !== undefined) {
            return SessionState.OK
          }
          return SessionState.INVALID
        } catch (error) {
          return SessionState.ERROR
        }
      }
      return SessionState.OK
    }, Transaction.READ_WRITE)();
  }

  async allRolesForLicense(licenseCode: string): Promise<string[]> {
    return this._fetch(this.data_url + "/users/allGroups?licenseCode=" + licenseCode) as Promise<string[]>
  }

  needRole(neededRole: string) {
    let roleFound = this.findRole(neededRole);
    if (!roleFound && "any" !== roleFound) navigateTo("/")
  }

  findRole(neededRole: string) {
    return withStore(this, () => {
      let foundRole: string | undefined = neededRole
      if (
        neededRole === "any" &&
        this.storeData().session?.user?.roles &&
        (this.storeData().session?.user?.roles?.length || 0) > 0
      ) {
        foundRole = "any"
      } else {
        foundRole = this.storeData().session?.user?.roles?.find(
          (role) => role === neededRole
        )
      }
      return foundRole
    })()
  }

  async setLocaleForSession(locale: string) {
    return withStoreAsync(this, async () => {
      let session = this.storeData().session
      if (session) {
        session.locale = locale
        await this._fetch(this.data_url + "/sessions/" + session.apiKey + "/update_locale", {
          method: "POST",
          body: {
            locale: locale
          }
        })
      }
    }, Transaction.READ_WRITE)()
  }

  logout() {
    withStore(this, () => {
      // googleLogout()
      setUserId('');
      this.storeData().session = undefined
      this.storageService.clear();
      this.closeSession().then(() => {
        window.location.href = '/'
      })
    })()
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    return this._fetch(this.data_url + "/users/updatePassword", {
      method: "POST",
      body: JSON.stringify({ oldPassword, newPassword })
    })
  }

  async fetchTransferCode() {
    return this._fetch((this.data_url + "/users/transfer-code"))
  }

  async transferUserUsingCodeToCurrentLicense(code: string) {
    return this._fetch(this.data_url + "/users/transfer", {
      method: "POST",
      body: code
    })
  }

  async sendPasswordResetMail(email: string) {
    return this._fetch(this.data_url + "/users/resetPassword", {
      method: "POST", body: JSON.stringify({
        email
      })
    })
  }

  async updateDeviceNameForSession(newDeviceName: string) {
    return this._fetch(this.data_url + '/sessions/update_device_name', {
      method: "PUT",
      body: newDeviceName
    })
  }

  async newClientSession(licenseCode: string, location: string, tablenumber: string) {
    let url = this.data_url + `/sessions/${licenseCode}/${location}/${tablenumber}`;
    return this._fetch(url);
  }

  async authorizeWithGoogleSub(sub: string, name: string, email: string): Promise<Session> {
    return withStoreAsync(this, async () => {
      const headers = new Headers();
      this.storeData().session = await this._fetch(this.data_url + "/sessions/google", { body: { name, sub, email }, headers, method: "POST" }) as Session;
      setUserId(this.storeData().session?.user?.username ?? 'No user found in session. After google login.')
      eventBus.value.emit("event:sessionUpdated");
      return this.storeData().session!!;
    }, Transaction.READ_WRITE)()
  }

  async storeLicense(license: License) {
    try {
      await this._fetch(this.data_url + "/licenses", { method: "POST", body: JSON.stringify(license) })
      return true;
    } catch (error) {
      log("error", "Error storing license: " + error)
    }
    return false;
  }

  async fetchLicenses(): Promise<any> {
    return await this._fetch(this.data_url + "/licenses")
  }

  async createAccount(email: string) {
    return await this._fetch(this.data_url + "/users", { method: "POST", body: JSON.stringify({ email }) })
  }

  async assignRoles(userId: string, roles: string[], licenseCode: string) {
    return await this._fetch(this.data_url + "/users/updateRoles/" + userId + "?licenseCode=" + licenseCode, { method: "POST", body: JSON.stringify(roles) })
  }

  async assignLocations(userId: string, locations: string[], licenseCode: string) {
    return await this._fetch(this.data_url + "/users/updateLocations/" + userId + "?licenseCode=" + licenseCode, { method: "POST", body: JSON.stringify(locations) })
  }

  async getAllSessions(locationName: string): Promise<Session[]> {
    return withStoreAsync(this, async () => {
      let sessions = await this._fetch(this.data_url + "/sessions?locationName=" + locationName)
      ObjectUtils.fillReferences(sessions)
      return sessions as Session[]
    })()
  }

  async closeSessionWithId(sessionId: string) {
    return withStoreAsync(this, async () => {
      try {
        await this._fetch(this.data_url + "/sessions/" + sessionId, {
          method: "DELETE"
        })
        return true;
      } catch (e) {
        return false;
      }
    })()
  }

  async closeSession() {
    return withStoreAsync(this, async () => {
      let sessionId = this.storeData().session?.apiKey
      try {
        await this._fetch(this.data_url + "/sessions/" + sessionId, {
          method: "DELETE"
        })
        return true;
      } catch (e) {
        return false;
      }
    })()
  }


  async authorize(
    userName: string,
    password: string
  ): Promise<Session> {
    return withStoreAsync(this, async () => {
      const headers = new Headers()
      headers.append("secret", password)
      try {
        this.storeData().session = (await this._fetch(
          this.data_url + "/sessions",
          {
            method: "POST",
            headers,
            body: {
              "username": userName,
              "password": password
            }
          }
        )) as Session
        eventBus.value.emit("event:sessionUpdated")
        setUserId(this.storeData().session?.user?.username ?? 'No user after login')
        return this.storeData().session!
      } catch (error) {
        return {
          user: {
            username: userName
          }
        } as Session
      }
    }, Transaction.READ_WRITE)()
  }
}

export enum SessionState {
  OK,
  INVALID,
  ERROR
}
