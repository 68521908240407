import { StoreData } from "@/services/storage/StoreData"
import Order from "@/model/Order"
import Session from "@/model/Session"
import { v4 as uuidv4 } from "uuid";
import { Item } from "~/model/referencedata/Item";

export default class StorageService {

  public static C_ENV_KEY = "$storedata"
  public static C_DEVICE_NAME = "$devicename"

  private storedData: StoreData = new StoreData()

  /*
  The init() method is called when the StorageService is initialized, and the localStorage is checked
  for an existing StoreData object. If it exists, assign it to this.storedData, otherwise, create a new
  StoreData object.
  */
  public init(defaultDataProvider?: () => StoreData): StoreData {
    if (import.meta.client) {
      let storeDataJson = localStorage.getItem(StorageService.C_ENV_KEY)
      if (storeDataJson) {
        try {
          this.storedData = Object.assign(
            new StoreData(), // create a new StoreData object
            JSON.parse(storeDataJson) as StoreData // Assign from localStore
          )
          this.storedData = reconstructData(this.storedData)
        } catch (e) {
          if (defaultDataProvider) {
            this.storedData = defaultDataProvider()
            this.commit()
          }
        }
      } else {
        if (defaultDataProvider) {
          this.storedData = defaultDataProvider()
          this.commit()
        }
      }
    }
    return this.storedData
  }

  /*
  This method returns a copy of this.storedData. A copy must be returned to avoid
  inconsistencies in the data.
  */
  public data(write: Boolean = false): StoreData {
    if (process.server) throw new Error("Cannot fetch local values on server.")
    return write
      ? this.storedData
      : this.addReadOnly(JSON.parse(JSON.stringify(this.storedData)))
  }

  public deviceName(): string {
    let deviceName = localStorage.getItem(StorageService.C_DEVICE_NAME)
    if (!deviceName) {
      deviceName = uuidv4()
      localStorage.setItem(StorageService.C_DEVICE_NAME, deviceName)
    }
    return deviceName;
  }

  public async setDeviceName(newName: string) {
    try {
      await services.authorizationService().updateDeviceNameForSession(newName);
      localStorage.setItem(StorageService.C_DEVICE_NAME, newName);
      return true;
    } catch (error) {
      return false;
    }
  }

  private addReadOnly(data: StoreData): StoreData {
    return reconstructData(data)
  }

  /*
  The commit() method takes the storedData and saves it as a stringified JSON
  object in the localStorage.
  */
  public commit(): void {
    if (import.meta.server) throw new Error("Cannot persist on server")
    let text: string = ""
    if (this.storedData) {
      text = JSON.stringify(this.storedData)
    }
    localStorage.setItem(StorageService.C_ENV_KEY, text)
  }

  public clear() {
    localStorage.clear();
  }
}
const reconstructData = (storedData: StoreData): StoreData => {
  if (storedData.session) {
    storedData.session = reconstructSession(storedData.session)
  }
  return storedData;
}

export const reconstructSession = (session: Session): Session => {
  if (session?.bindingUrl == undefined) {
    let reconstructed = Object.assign(new Session(), session)
    if (reconstructed.orders && reconstructed.orders.length > 0) {
      reconstructed.orders = reconstructOrders(reconstructed.orders)
      reconstructed.orders.forEach(order => {
        order.rows = order.rows.map(row => Object.assign(new Item(), row))
      })
    }
    return reconstructed;
  }
  return session;
}

export const reconstructOrders = (orders: Order[]): Order[] => {
  if (orders) {
    return orders.map((order) => {
      return Object.assign(new Order(), order);
    })
  }
  return [];
}
