import CustomerType from "./CustomerType";
import { Restriction } from "./Restriction";
import { Item } from "./Item";
import Page from "./Page";

export default interface ReferenceData {
  version: string;
  customerTypes: CustomerType[];
  restrictions: Restriction[];
  items: Item[];
  pages: Page[];
  paymentTypes: string[];
  currency: string | undefined;
  currencyFormat: string | undefined;
  sourceUrl: string | undefined;
  minutesPerSession: number | undefined;
}

const defaultReferenceData: ReferenceData = {
  version: "1",
  customerTypes: [],
  restrictions: [],
  items: [],
  pages: [],
  paymentTypes: [],
  currency: "EURO",
  currencyFormat: "nl-NL",
  sourceUrl: undefined,
  minutesPerSession: 180
} as ReferenceData;

export const createDefaultReferenceData = () => {
  return structuredClone(defaultReferenceData)
}
